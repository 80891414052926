exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-common-footer-tsx": () => import("./../../../src/pages/common/footer.tsx" /* webpackChunkName: "component---src-pages-common-footer-tsx" */),
  "component---src-pages-common-header-tsx": () => import("./../../../src/pages/common/header.tsx" /* webpackChunkName: "component---src-pages-common-header-tsx" */),
  "component---src-pages-common-heroshot-tsx": () => import("./../../../src/pages/common/heroshot.tsx" /* webpackChunkName: "component---src-pages-common-heroshot-tsx" */),
  "component---src-pages-common-navbar-tsx": () => import("./../../../src/pages/common/navbar.tsx" /* webpackChunkName: "component---src-pages-common-navbar-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-homepage-benefits-tsx": () => import("./../../../src/pages/homepage/benefits.tsx" /* webpackChunkName: "component---src-pages-homepage-benefits-tsx" */),
  "component---src-pages-homepage-contact-form-tsx": () => import("./../../../src/pages/homepage/contactForm.tsx" /* webpackChunkName: "component---src-pages-homepage-contact-form-tsx" */),
  "component---src-pages-homepage-contacto-tsx": () => import("./../../../src/pages/homepage/contacto.tsx" /* webpackChunkName: "component---src-pages-homepage-contacto-tsx" */),
  "component---src-pages-homepage-offer-tsx": () => import("./../../../src/pages/homepage/offer.tsx" /* webpackChunkName: "component---src-pages-homepage-offer-tsx" */),
  "component---src-pages-homepage-tagline-tsx": () => import("./../../../src/pages/homepage/tagline.tsx" /* webpackChunkName: "component---src-pages-homepage-tagline-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-planes-tsx": () => import("./../../../src/pages/planes.tsx" /* webpackChunkName: "component---src-pages-planes-tsx" */),
  "component---src-pages-registro-trial-tsx": () => import("./../../../src/pages/registro-trial.tsx" /* webpackChunkName: "component---src-pages-registro-trial-tsx" */),
  "component---src-pages-registro-tsx": () => import("./../../../src/pages/registro.tsx" /* webpackChunkName: "component---src-pages-registro-tsx" */),
  "component---src-pages-terminos-tsx": () => import("./../../../src/pages/terminos.tsx" /* webpackChunkName: "component---src-pages-terminos-tsx" */)
}

